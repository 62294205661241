import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Layout from '../../components/layout';
import Seo from '../../components/seo';

const SvgToJpegPngConverter = () => {
  const [convertedImage, setConvertedImage] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [format, setFormat] = useState('image/jpeg');
  const [bgColor, setBgColor] = useState('#ffffff');
  const [transparentBg, setTransparentBg] = useState(false);
  const [outputWidth, setOutputWidth] = useState(800);

  const convertImage = async (file) => {
    if (!file) {
      setError('Please select a file');
      return;
    }

    setIsLoading(true);
    setError(null);
    setConvertedImage(null);

    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const svgString = e.target.result;
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');
        const svgElement = svgDoc.documentElement;

        if (!svgElement || svgElement.nodeName !== 'svg') {
          throw new Error('Invalid SVG input');
        }

        let viewBox = svgElement.getAttribute('viewBox');
        let width, height;
        if (viewBox) {
          [, , width, height] = viewBox.split(' ').map(Number);
        } else {
          width = parseInt(svgElement.getAttribute('width')) || 300;
          height = parseInt(svgElement.getAttribute('height')) || 150;
        }

        const aspectRatio = width / height;
        const newHeight = Math.round(outputWidth / aspectRatio);

        const canvas = document.createElement('canvas');
        canvas.width = outputWidth;
        canvas.height = newHeight;

        const ctx = canvas.getContext('2d');

        if (!transparentBg) {
          ctx.fillStyle = bgColor;
          ctx.fillRect(0, 0, outputWidth, newHeight);
        }

        const img = new Image();
        img.onload = () => {
          ctx.drawImage(img, 0, 0, outputWidth, newHeight);
          const dataUrl = canvas.toDataURL(format);
          setConvertedImage(dataUrl);
          setIsLoading(false);
        };
        img.onerror = () => {
          setError('Error loading the SVG image.');
          setIsLoading(false);
        };
        img.src = URL.createObjectURL(new Blob([svgString], { type: 'image/svg+xml' }));
      };
      reader.readAsText(file);
    } catch (error) {
      console.error('Conversion failed:', error);
      setError('Conversion failed. Please try again.');
      setIsLoading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      convertImage(acceptedFiles[0]);
    }
  }, [outputWidth, format, bgColor, transparentBg]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    accept: {
      'image/svg+xml': ['.svg']
    },
    multiple: false
  });

  return (
    <Layout>
      <Seo 
        title="SVG to JPEG/PNG Converter" 
        description="Convert SVG files to JPEG or PNG format online. Free tool with options for background color, transparency, and custom output dimensions. No upload required."
      />
      <div className="max-w-2xl mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">SVG to JPEG/PNG Converter</h1>
        <div className="mb-4">
          <label className="block mb-2">
            <input
              type="radio"
              value="image/jpeg"
              checked={format === 'image/jpeg'}
              onChange={(e) => setFormat(e.target.value)}
              className="mr-2"
            />
            JPEG
          </label>
          <label className="block mb-2">
            <input
              type="radio"
              value="image/png"
              checked={format === 'image/png'}
              onChange={(e) => setFormat(e.target.value)}
              className="mr-2"
            />
            PNG
          </label>
        </div>
        <div className="mb-4">
          <label className="block mb-2">
            Background Color:
            <input
              type="color"
              value={bgColor}
              onChange={(e) => setBgColor(e.target.value)}
              className="ml-2"
              disabled={transparentBg}
            />
          </label>
          <label className="block mb-2">
            <input
              type="checkbox"
              checked={transparentBg}
              onChange={(e) => setTransparentBg(e.target.checked)}
              className="mr-2"
            />
            Transparent Background
          </label>
        </div>
        <div className="mb-4">
          <label className="block mb-2">
            Output Width:
            <input
              type="number"
              value={outputWidth}
              onChange={(e) => setOutputWidth(parseInt(e.target.value))}
              className="ml-2 border rounded p-1"
              min="1"
            />
          </label>
        </div>
        <div 
          {...getRootProps()} 
          className={`border-2 border-dashed p-8 mb-4 text-center cursor-pointer ${
            isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
          }`}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the SVG file here ...</p>
          ) : (
            <p>Drag 'n' drop an SVG file here, or click to select a file</p>
          )}
        </div>
        {isLoading && (
          <div className="flex justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            <p className="ml-3">Converting image...</p>
          </div>
        )}
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {convertedImage && (
          <div className="mt-4">
            <h3 className="text-xl font-semibold mb-2">Converted Image:</h3>
            <img src={convertedImage} alt="Converted" className="max-w-full h-auto mb-2" />
            <a 
              href={convertedImage} 
              download={`converted_image.${format === 'image/jpeg' ? 'jpg' : 'png'}`}
              className="inline-block bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Download Image
            </a>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SvgToJpegPngConverter;